<template>
  <div v-click-outside="closeMenu" class="ui-menu">
    <div @click="changeVisibleMenu" ref="activator" class="ui-menu__activator">
      <slot name="activator"></slot>
    </div>
    <transition :name="transition">
      <div v-if="value" ref="body" class="ui-menu__body" :style="position">
        <slot name="body"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "UiMenu",

  props: {
    value: Boolean,
    transition: {
      type: String,
      default: 'slide-bottom',
    }
  },

  data() {
    return {
      visibleBody: false,
      position: {}
    }
  },


  methods: {
    changeVisibleMenu() {
      this.$emit('input', !this.value)
      if(!this.value) {
        this.$nextTick(() => {
          const left = this.$refs.activator.getBoundingClientRect().left
          const activatorPosition = this.$refs.activator.getBoundingClientRect()
          const body = this.$refs.body

          if((left + body.clientWidth) > window.innerWidth) {
            this.position = {
              left: window.innerWidth - body.clientWidth + 'px',
              top: (activatorPosition.top + activatorPosition.height)  + 'px',
            }
          }
          else {
            this.position = {
              left: left + 'px',
              top: (activatorPosition.top + activatorPosition.height)  + 'px',
            }
          }
        })
      }
    },

    closeMenu() {
      this.$emit('input', false)
    }
  },
}
</script>

<style lang="scss" scoped>
.ui-menu {
  position: relative;
  z-index: 10;

  &__body {
    position: fixed;
    transition: 0.3s;
  }
}
</style>
