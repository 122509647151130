<template>
  <div :class="['accordion', { disabled: disabled }]">
    <div @click="openBody" :class="['accordion__header', { background: isOpen || value && value.text === item.text }]">
      <div class="accordion__header-left">
        <div class="circle" v-if="!isShowArrow" :style="{backgroundColor: isOpen ? '#E21F1F' : 'black'}"></div>
        <img v-if="item.icon" :src="getIconColor" alt="" />
        <p class="link-left__text">{{ item.text }}</p>
      </div>
      <svg v-if="!disabled && isShowArrow" :class="['accordion__header-arrow', { rotate: isOpen ||  value && value.text === item.text }]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4664 15.4483C13.0369 16.1839 11.9631 16.1839 11.5336 15.4483L8.15117 9.65517C7.72165 8.91954 8.25855 8 9.11758 8L15.8824 8C16.7415 8 17.2783 8.91954 16.8488 9.65517L13.4664 15.4483Z" :fill="arrowColor"/>
      </svg>
      <img v-if="disabled && isShowArrow" src="@/assets/svg/arrows/arrow-down-grey.svg" alt="" />
    </div>
    <transition name="accordion">
      <div v-if="isOpen" class="accordion__body">
        <div class="accordion__body-inner">
          <slot name="body" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Accordion",

  data() {
    return {
      isOpen: false,
    }
  },

  props: {
    value: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    opened: {
      type: Boolean,
      default: false
    },
    outsideMenu: {
      type: Boolean,
      default: false
    },
    isShowArrow: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters(['getUser']),
    arrowColor () {
      return this.isOpen ? '#E21F1F' : '#343432'
    },

    getIconColor () {
      return this.disabled ? this.item.disabledIcon : this.item.icon
    }
  },

  mounted() {
    this.isOpen = this.opened
  },

  methods: {
    openBody() {
     if (!this.disabled) {
       this.isOpen = !this.isOpen
     }
    },
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  &__header {
    display: flex;
    gap: 10px;
    width: 100%;
    padding: 25px 20px 25px 0;
    cursor: pointer;

    @media (max-width: 768px) {
      padding: 20px 20px 20px 12px;
    }

    &-left {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: #343432;
      }
    }

    &-arrow {
      width: 24px;
      height: 24px;
      transition: .3s;
      transform: rotate(0deg);

      &.rotate {
        transition: .3s;
        transform: rotate(-180deg);

        //@media (max-width: 768px) {
        //  transform: rotate(-180deg);
        //}
      }
    }
  }

  &__body {
    transition: 150ms ease-out;

    @media (max-width: 768px) {
      background: #FFFFFF;
      padding: 20px 12px;
      box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
      border-radius: 20px;
    }
  }
}
.disabled {
  .accordion__header {
    cursor: default;
    img {
      filter: grayscale(100%)
    }
    &-left {
      img {
        filter: grayscale(100%)
      }
      p {
        color: #BFBDBD !important;
      }
    }
  }
}

.background {
  .accordion__header-left {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #E21F1F !important;
    }
  }
}
.circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
</style>
