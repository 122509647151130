<template>
  <div class="region-informer">
    <div class="region-informer__navbar">
      <div class="region-informer__navbar-top">
        <h1>{{ $t('calculation-of-regions-rating-directories') }}</h1>
        <div class="region-informer__navbar-top-items">
          <router-link
              to="/admin/region-informer/periods"
              class="incident-types"
              @click.native="closeAccordion"
          >{{ $t('periods') }}</router-link>
          <router-link
              to="/admin/region-informer/regions"
              class="incident-types"
              @click.native="closeAccordion"
          >{{ $t('regions') }}</router-link>
          <router-link
              to="/admin/region-informer/types"
              class="incident-types"
              @click.native="closeAccordion"
          >{{ $t('type-2') }}</router-link>
          <router-link
              to="/admin/region-informer/subtypes"
              class="incident-types"
              @click.native="closeAccordion"
          >{{ $t('subtype') }}</router-link>
          <router-link
              to="/admin/region-informer/groups"
              class="incident-types"
              @click.native="closeAccordion"
          >{{ $t('group') }}</router-link>
          <accordion :item="{ text: $t('indicators') }" ref="accordion">
            <template #body>
              <router-link to="/admin/region-informer/basic" class="accordion__body-inner-text">{{ $t('base') }}</router-link>
              <router-link to="/admin/region-informer/final" class="accordion__body-inner-text">{{ $t('totals') }}</router-link>
            </template>
          </accordion>
          <router-link
              to="/admin/region-informer/algorithms"
              class="incident-types"
              @click.native="closeAccordion"
          >{{ $t('algorithms') }}</router-link>
        </div>
      </div>
      <ui-menu v-model="visibleSettings">
        <template #activator>
          <img alt="" :src="require(`@/assets/svg/icon-settings-${settingsColor}.svg`)" class="nav-bar__settings" />
        </template>
        <template #body>
          <div class="sort">
            <router-link
                to="/admin/region-informer/regions"
                class="incident-types"
                @click.native="visibleSettings = !visibleSettings"
            >{{ $t('regions') }}</router-link>
            <router-link
                to="/admin/region-informer/types"
                class="incident-types"
                @click.native="visibleSettings = !visibleSettings"
            >{{ $t('type-2') }}</router-link>
            <router-link
                to="/admin/region-informer/subtypes"
                class="incident-types"
                @click.native="visibleSettings = !visibleSettings"
            >{{ $t('subtype') }}</router-link>
            <router-link
                to="/admin/region-informer/groups"
                class="incident-types"
                @click.native="visibleSettings = !visibleSettings"
            >{{ $t('group') }}</router-link>
            <accordion :item="{ text: 'Показатели' }">
              <template #body>
                <router-link to="/admin/region-informer/basic" class="accordion__body-inner-text" @click.native="visibleSettings = !visibleSettings">{{ $t('base') }}</router-link>
                <router-link to="/admin/region-informer/final" class="accordion__body-inner-text" @click.native="visibleSettings = !visibleSettings">{{ $t('totals') }}</router-link>
              </template>
            </accordion>
            <router-link
                to="/admin/region-informer/algorithms"
                class="incident-types"
                @click.native="visibleSettings = !visibleSettings"
            >{{ $t('algorithms') }}</router-link>
          </div>
        </template>
      </ui-menu>
    </div>
    <router-view />
  </div>
</template>

<script>
import UiMenu from "@/components/ui/UiMenu.vue";
import Accordion from "@/components/template/work-requests/ViewRequests/Accordion.vue";
export default {
  name: "Index",
  components: {Accordion, UiMenu},

  data() {
    return {
      visibleSettings: false,
      links: [
        {id: 0, name: this.$t('regions'), path: '/admin/region-informer/regions'},
        {id: 1, name: this.$t('type-2'), path: '/admin/region-informer/types'},
        {id: 2, name: this.$t('subtype'), path: '/admin/region-informer/subtypes'},
        {id: 3, name: this.$t('group'), path: '/admin/region-informer/groups'},
        {id: 4, name: this.$t('base'), path: '/admin/region-informer/basic'},
        {id: 5, name: this.$t('totals'), path: '/admin/region-informer/final'},
        {id: 6, name: this.$t('algorithms'), path: '/admin/region-informer/algorithms'}
      ]
    }
  },

  computed: {
    settingsColor () {
      return this.visibleSettings ? 'red' : 'black'
    }
  },

  methods: {
    closeAccordion () {
      this.$refs.accordion.isOpen = false;
    }
  },

  mounted() {
    if(this.$route.path === '/admin/region-informer/basic' || this.$route.path === '/admin/region-informer/final') {
      this.$refs.accordion.isOpen = true
    }
  }

}
</script>

<style lang="scss" scoped>
.region-informer {
  display: flex;
  gap: 30px;
  min-height: 100vh;
  height: 100%;
  padding: 150px 50px 150px 50px;

  @media(max-width: 1200px) {
    flex-direction: column;
    padding: 120px 20px 50px 20px;
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    max-width: 320px;
    width: 100%;

    @media (max-width: 1200px) {
      flex-direction: row;
      max-width: 100%;
    }

    &-top {
      display: flex;
      flex-direction: column;
      gap: 50px;

      h1 {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        color: #1B1A1F;
      }

      &-items {
        display: flex;
        flex-direction: column;

        @media (max-width: 1200px) {
          display: none;
        }

        .incident-types {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #1B1A1F;
          margin-bottom: 20px;
          white-space: nowrap;
        }

        .accordion-incident-types {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 13px;
          color: #343432;
          text-decoration: none;
        }

        .router-link-active {
          color: #CE2121
        }
      }
    }

    &-bottom {
      color: #9A9A9A;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;

      @media (max-width: 1200px) {
        position: absolute;
        top: 30px;
      }
    }
  }
}
.nav-bar__settings {
  display: none;

  @media (max-width: 1200px) {
    display: inline-block;
  }
}
::v-deep .accordion {
  margin-bottom: 20px;

  @media (max-width: 1200px) {
    margin-bottom: 0;
  }
  &__header {
    padding: 0 !important;
  }

  &__body {
    padding-left: 15px;

    &-inner {
      gap: 16px;
      display: flex;
      flex-direction: column;

      &-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 10px;

        &.router-link-active {
          &::before {
            background-color: #CE2121 !important;
          }
        }

        &::before {
          content: '';
          width: 5px;
          height: 5px;
          background-color: black;
          border-radius: 50%;
        }
      }
    }
 }
}
::v-deep .router-link-active {
  color: #CE2121
}
</style>